<template>
  <div>
    <auth-base
      :action="action"
      :method="'put'"
      :formKey="formKey"
      :submitType="'json'"
      :onSuccess="onSuccess"
      :additionalData="{ token }"
    >
      <template #title>
        <h1 v-if="success">Set new password</h1>
        <h1 v-else>Set new password</h1>
      </template>

      <template>
        <p class="description" v-if="success">
          You will be autmatically redirected to the home page. In case you are not redirected,
          please use your new password to <slot name="backToLogin"></slot>
        </p>
        <template v-else>
          <p>Please enter your new password.</p>

          <ez-input
            type="password"
            label="New password"
            placeholder="Enter Your Password"
            name="password"
            @onChange="val => (password = val)"
            :formKey="formKey"
          />
          <ez-input
            class="mt-12"
            formKey="confirmPass"
            :error-msg="errorMessagePass"
            placeholder="Enter Your Password Again"
            type="password"
            @onChange="val => (confirmPass = val)"
            name="confirm"
            label="Confirm Your Password"
          />

          <ez-button
            form-type="submit"
            isFullWidth
            :disabled="!isMatch || password === '' || !termsAndConditionsChecked"
          >
            Set password
          </ez-button>

          <slot></slot>
        </template>
      </template>
    </auth-base>
    <div v-if="!success" class="terms-and-conditions mt-12">
      <checkbox
        class="terms-and-conditions__checkbox mr-8"
        name="termsAndConditions"
        :checked="termsAndConditionsChecked"
        @change="onTermsAndConditionsChange"
      />
      <span class="terms-and-conditions__label">
        I agree to the
        <router-link :to="{ name: 'terms-and-conditions' }" target="_blank">
          Terms and Conditions
        </router-link>
        and
        <router-link :to="{ name: 'privacy-policy' }" target="_blank"> Privacy Policy </router-link
        >.
      </span>
    </div>
  </div>
</template>

<script>
import EzInput from '@/components/ui/Input';
import EzButton from '@/components/ui/Button';
import Checkbox from '@/components/ui/Checkbox/Checkbox.vue';
import AuthBase from './AuthBase.vue';

export default {
  components: {
    AuthBase,
    EzInput,
    EzButton,
    Checkbox,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      success: false,
      password: '',
      confirmPass: '',
      token: '',
      formKey: 'form-set-password',
      termsAndConditionsChecked: false,
    };
  },
  computed: {
    isMatch() {
      return this.password === this.confirmPass;
    },
    errorMessagePass() {
      return this.isMatch ? '' : 'Confirm field does not match';
    },
  },
  methods: {
    onSuccess(response) {
      this.$emit('success', {
        response,
        password: this.password,
      });
      this.success = true;
    },
    onTermsAndConditionsChange() {
      this.termsAndConditionsChecked = !this.termsAndConditionsChecked;
    },
  },
  mounted() {
    this.token = this.$route.params.token;
  },
};
</script>

<style scoped lang="scss">
.terms-and-conditions {
  margin: 0 auto;
  display: flex;
  align-items: center;
  max-width: 20rem;
  &__checkbox {
    display: inline;
  }

  &__label {
    @include font-size(14px);
    color: $text-color;
    a {
      display: inline-block;
      color: $color-primary-blue;
      text-decoration: none;
    }
  }
}

p.description :deep() a {
  display: inline;
}

@media (max-width: 768px) {
  :deep() .input-group .input-group__input input {
    font-size: 16px;
  }
}
</style>

<template>
  <auth-base
    :action="action"
    :formKey="formKey"
    :additionalData="hiddenData"
    :submitType="'urlencoded'"
    :onSuccess="onSuccess"
    :onError="onError"
  >
    <template #title>
      <h1>Sign in to platform</h1>
    </template>

    <ez-input
      type="email"
      label="Email address"
      placeholder="admin@example.com"
      name="username"
      v-bind:email.sync="email"
      v-on:onChange="email = $event"
      :error="!!errorMessage"
      :formKey="formKey"
    ></ez-input>

    <ez-input
      class="mt-12"
      type="password"
      label="Password"
      name="password"
      v-bind:password.sync="password"
      v-on:onChange="password = $event"
      :error="!!errorMessage ? { message: errorMessage } : false"
      :formKey="formKey"
    ></ez-input>

    <ez-button form-type="submit" isFullWidth>Sign in</ez-button>

    <slot name="resetPassword"></slot>
  </auth-base>
</template>

<script>
import flash from '@/components/ui/FlashMessage';
import EzInput from '@/components/ui/Input';
import EzButton from '@/components/ui/Button';
import AuthBase from './AuthBase.vue';

export default {
  components: {
    AuthBase,
    EzInput,
    EzButton,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      formKey: 'form-login',
      hiddenData: {
        client_id: 'web',
        grant_type: 'password',
      },
    };
  },
  methods: {
    onSuccess(response) {
      this.$emit('loginSuccess', response);
    },
    onError() {
      flash.error({
        title: 'Sign in failed!',
        message: 'There was an error with your E-Mail/Password combination. Please try again.',
      });

      this.$emit('loginError');
    },
  },
};
</script>

<style scoped lang="scss">
:deep() .auth__form h1 {
  margin: 0 0 2rem;
}

@media (max-width: 768px) {
  :deep() .input-group .input-group__input input {
    font-size: 16px;
  }
}
</style>

<template>
  <set-password action="/manager/set-password" @success="handleSuccess">
    <template #backToLogin>
      <router-link :to="{ name: 'platform-login' }">Sign In.</router-link>
    </template>
  </set-password>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import { SetPassword } from '@/views/common/auth';
import httpService from '@/api/http';
import qs from 'qs';
import flash from '@/components/ui/FlashMessage';
import { getAccountOwnerIdForLoggedUser, isLocalStorageAccessSafeFlash } from '@/util/utils';
import productsPermissionFix from '@/util/productsPermissionFix';
import { isAdmin } from '@/util/constants';

export default {
  components: {
    SetPassword,
  },
  computed: {
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
    logInAction() {
      if (this.platformName === 'Foodrazor') return '/venue/oauth';
      return '/manager/oauth';
    },
  },
  methods: {
    ...mapActions('entities/users', ['initVenueLoggedUser', 'initDistributorLoggedUser']),
    ...mapActions('defaultImages', ['fetchDefaultImages']),
    ...mapMutations('reports', ['UPDATE_FILTERS_TO_DOS']),
    initIntercom() {
      window.Intercom('boot', {
        app_id: process.env.VUE_APP_INTERCOM_APP_ID,
      });
    },
    async handleSuccess(res) {
      const response = await httpService.post(
        this.logInAction,
        qs.stringify({
          username: res.response.data.data.email,
          password: res.password,
          client_id: 'web',
          grant_type: 'password',
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      this.handleLoginSuccess(response);
    },
    handleLoginSuccess(loginResponse) {
      const tokenData = loginResponse.data.data;

      this.setTokens(tokenData);

      this.initUser(tokenData.scope)
        .then(() => {
          this.fetchDefaultImages();
          if (productsPermissionFix() === 'venue-orders') {
            this.$router.push({ name: 'venue-orders' });
          } else if (productsPermissionFix() === 'venue-orders-history') {
            this.$router.push({ name: 'venue-orders-history' });
          } else {
            this.$router.push({ name: 'platform-home' });
          }
          if (!isAdmin()) {
            this.UPDATE_FILTERS_TO_DOS({ accountOwnerId: getAccountOwnerIdForLoggedUser() });
          }
        })
        .catch(error => {
          this.resetTokens();

          flash.error({
            title: error.message,
          });

          throw error;
        });
    },
    async initUser(scope) {
      switch (scope) {
        case 'distributor':
          return this.initDistributorLoggedUser();
        case 'venue':
          return this.initVenueLoggedUser();
        default:
          return Promise.reject(new Error(`Invalid scope ${scope}`));
      }
    },
    setTokens(tokens) {
      const {
        accessToken,
        accessTokenExpiresAt,
        refreshToken,
        refreshTokenExpiresAt,
        scope,
        user,
      } = tokens;
      // Get permissions for the logged in user.
      const { permissions } = user;

      if (!isLocalStorageAccessSafeFlash()) {
        return;
      }

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('accessTokenExpiresAt', accessTokenExpiresAt);
      localStorage.setItem('accessTokenExpiresAt', accessTokenExpiresAt);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('refreshTokenExpiresAt', refreshTokenExpiresAt);
      localStorage.setItem('role', scope);
      localStorage.setItem('permissions', JSON.stringify(permissions));
    },
    resetTokens() {
      this.EMPTY_CART();

      if (!isLocalStorageAccessSafeFlash()) {
        return;
      }
      localStorage.clear();
    },
    ...mapMutations('cart', ['EMPTY_CART']),
  },
  created() {
    this.initIntercom();
  },
};
</script>

<style scoped lang="scss"></style>

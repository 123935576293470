<template>
  <div :class="['input-group', { disabled }, { error }]">
    <label
      @click="onClick"
      :for="id"
      :class="{
        'input-group__label': true,
        'input-group__label--none': !hasLabel,
        'input-group__label--right': labelPosition === 'right',
        'input-group__label--left': labelPosition === 'left',
       }"
       :data-cy="dataCy"
      >
      <input
        ref="checkbox"
        :id="id"
        :name="name"
        :value="value"
        :disabled="disabled"
        :required="required"
        type="checkbox"
        :checked="checked"
        class="input-group__input"
        @change="onChange"
      />
      <span class="check-indicator"></span>
      <span v-if="label">
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script>
import uuid from 'uuid/v4';
import { mapGetters } from 'vuex';

export default {
  props: {
    /**
     * Key from the parent form
     */
    formKey: {
      required: false,
      type: String,
    },
    /**
     * Input name
     */
    name: {
      required: false,
      type: String,
    },
    /**
     * Input value
     */
    value: {
      required: false,
      type: String,
    },
    /**
     * Checked state
     */
    checked: {
      required: false,
      default: false,
      type: Boolean,
    },
    /**
     * Label that is above the input.
     */
    label: {
      required: false,
      type: String,
    },
    labelPosition: {
      required: false,
      default: 'right',
      type: String,
      validator: value => ['left', 'right'].includes(value),
    },
    /**
     * If the input is disable or not.
     */
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * If the input is required or not.
     */
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Third checkbox state.
     */
    indeterminate: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * Propagate Event.
     * Useful inside a table when a table has a click event.
     */
    stopPropagation: {
      required: false,
      type: Boolean,
      default: false,
    },
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: null,
    };
  },
  computed: {
    error() {
      return this.getError();
    },
    hasLabel() {
      return this.label && this.label.length > 0;
    },
    ...mapGetters('errors', ['getError']),
  },
  watch: {
    indeterminate() {
      this.$refs.checkbox.indeterminate = this.indeterminate;
    },
  },
  methods: {
    onClick(e) {
      if (this.stopPropagation) e.stopPropagation();
    },
    onChange(e) {
      this.$emit('change', e.target.checked);
    },
  },
  mounted() {
    this.id = uuid();
    this.$refs.checkbox.indeterminate = this.indeterminate;
  },
};
</script>

<style scoped lang="scss">
$border-color:              #FFFFFF;
$input-border-error-color:  #EA4B5D;
$input-disabled-bg-color:   #E8ECF7;
$input-border-color:        #DEE1E4;
$checked-color:             #4D7CFE;

.input-group {
  @include font-size(14px);

  &:not(.height-auto) {
    height: 14px;
  }

  &__label {
    display: inline-flex;
    align-items: center;
    &--right {
      .check-indicator {
        margin-right: .5em;
      }
    }
    &--left {
      flex-direction: row-reverse;
      justify-content: flex-end;
      .check-indicator {
        margin-left: .5em;
      }
    }

    &--none {
      .check-indicator { margin: 0; }
    }
  }
  &__input {
    display: none;
    position: absolute;
    left: -999999;

    ~ .check-indicator {
      background-color: $border-color;
      border: 1px solid $input-border-color;
      border-radius: .15rem;
      display: inline-block;
      width: .875rem;
      height: .875rem;
      position: relative;
      cursor: pointer;
    }
    &:indeterminate ~ .check-indicator,
    &:checked ~ .check-indicator {
      background-color: $checked-color;
      border-color: $checked-color;
      &:after {
        position: absolute;
        top: .1em;
        left: .3em;
        content: '';
        width: .295em;
        height: .56em;
      }
    }

    &:checked ~ .check-indicator:after {
      transform: rotate(45deg);
      border: 1px solid $border-color;
      border-top: 0;
      border-left: 0;
    }

    &:indeterminate ~ .check-indicator:after {
      transform: rotate(90deg);
      border-right: 2px solid $border-color;
    }
  }
  &.disabled {
    .check-indicator {
      opacity: .5;
    }
  }
  &.error {
    .check-indicator {
      background-color: $input-border-error-color;
    }
  }
}
</style>

<template>
  <div class="auth">
    <center-layout>
      <template #heading>
        <div class="auth__heading">
          <img v-if="oezPlatformName === 'Foodrazor'" src="@/assets/Foodrazor-logo.svg" alt="Foodrazor logo">
          <img v-else src="@/assets/logo-dark.svg" alt="EZ logo">
        </div>
      </template>
      <ez-form
        class="auth__form"
        :showLoader="false"
        :formKey="formKey"
        :action="action"
        :method="method"
        :additionalData="additionalData"
        :submitType="submitType"
        @success="onSuccess"
        @error="onError"
      >
        <slot name="title"></slot>
        <slot></slot>
      </ez-form>
    </center-layout>
  </div>
</template>

<script>
import EzForm from '@/components/ui/Form';
import CenterLayout from '@/components/layout/Center.vue';

export default {
  components: {
    CenterLayout,
    EzForm,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: false,
      default: 'post',
    },
    formKey: {
      type: String,
      required: true,
    },
    additionalData: {
      type: Object,
      required: false,
    },
    submitType: {
      type: String,
      required: false,
    },
    onSuccess: {
      type: Function,
      required: false,
    },
    onError: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  computed: {
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color:   #4D7CFE;
$label-color:     #8790A3;
$default-color:   #252631;
$text-color:      #6C7995;

.auth {
  width: 100%;
  height: 100%;
  &__heading{
    @extend %flex-center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5rem 0 1rem 0;
    margin: 0 auto;
    width: 7.75rem;
    display: block;
    span {
      @include font-size(20px);
      color: $label-color;
      margin-left: 0.5rem;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  :deep() &__form {
    margin: 0 auto;
    h1 {
      @include font-size(24px);
      font-weight: 500;
      text-align: center;
      margin: 0;
    }
    a {
      display: block;
      color: $primary-color;
      text-align: center;
      text-decoration: none;
      margin-top: 1.5rem;
      @include font-size(14px);
    }
    p {
      @include font-size(14px);
      color: $text-color;
      text-align: center;
      line-height: 1.75;
      margin: .5rem 0 2rem 0;
      a {
        display: inline-block;
        color: $text-color;
        text-decoration: underline;
      }
    }
    button {
      margin-top: 1rem;
    }
    :deep() .input-group {
      width: 100%;
      + .input-group {
        margin-top: 1rem;
      }
      &__input {
        height: 2.5rem;
      }
    }
  }
}
</style>

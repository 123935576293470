<template>
  <auth-base
    :action="action"
    :formKey="formKey"
    :submitType="'json'"
    :onSuccess="onSuccess"
    :showLoader="false">
    <template #title>
      <h1 v-if="emailSent">Password Email Reset Sent</h1>
      <h1 v-else>Reset password</h1>
    </template>
    <template>
      <p v-if="emailSent">We sent a recovery link to your email address.</p>
      <p v-else>Enter your email address below and we'll send you a link to reset your password.</p>
    </template>
    <ez-input
      type="email"
      label="Email address"
      name="email"
      placeholder="email@example.com"
      validators="email|required"
      @validationChange="handleValidationChange"
      ref="emailInput"
      :disabled="emailSent"
      :formKey="formKey"></ez-input>

    <ez-button
      form-type="submit"
      isFullWidth
     :disabled="emailSent || !isValidEmail">Send Reset Email</ez-button>

    <slot name="backToLogin"></slot>
  </auth-base>
</template>

<script>
import EzInput from '@/components/ui/Input';
import EzButton from '@/components/ui/Button';
import AuthBase from './AuthBase.vue';

export default {
  components: {
    AuthBase,
    EzInput,
    EzButton,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isValidEmail: false,
      emailSent: false,
      formKey: 'form-forgot-password',
    };
  },
  methods: {
    handleValidationChange({ valid }) {
      this.isValidEmail = valid;
    },
    onSuccess() {
      this.emailSent = true;
    },
  },
};
</script>
<style scoped lang="scss">
  :deep() .auth__form a span {
    margin-left: .5rem;
  }
</style>
